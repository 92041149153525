import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Servicios, setDataContact } from "../Control/Firestore";
import { useLocation } from "react-router-dom";
import '../estilos/aviso.css';

const Aviso = () => {

  return (
    <div>
      <div className="inner-header flex">
        <h1 className='title-wave'>Aviso de privacidad</h1>
        <p>Con lo establecido los datos personales contenidos en el presente se
          encuentran protegidos por la Ley Federal de Protección de Datos
          Personales en Posesión de Particulares y serán tratados por:
          Grupo Greek con domicilio en  Acapulco de Juarez, Guerrero; en los términos de nuestro Aviso de
          Privacidad. Grupo Greek se compromete a
          guardar estricta confidencialidad de sus datos personales, así como a
          llevar a cabo esfuerzos razonables para su protección. Los datos
          personales que recabamos de usted serán utilizados para las siguientes
          finalidades, las cuales son necesarias para dar seguimiento a sus
          proyectos. Para llevar a cabo el presente aviso de privacidad,
          utilizaremos los siguientes datos personales: 
          - Nombre 
          - Edad 
          - Sexo
          - Teléfono fijo y/o celular 
          - Correo electrónico 
          - Red Social de contacto
          - Dirección 
          - Experiencia Laboral 
          Por otra parte, le informamos que sus datos personales no serán
          compartidos con ninguna autoridad, empresa, organización o persona
          distinta a nosotros y serán utilizados exclusivamente para los fines
          señalados. Usted tiene en todo momento el derecho a conocer que datos
          personales tenemos de usted, para qué los utilizamos y las condiciones
          de uso que les damos (Acceso). Asimismo, es un derecho solicitar la
          corrección de su información personal en caso de que esté
          desactualizada, sea inexacta o incompleta (Rectificación); de igual
          manera, tiene derecho a que su información se elimine de nuestros
          registros o bases de datos cuando considere que la misma no está
          siendo utilizada adecuadamente (Cancelación); así como también
          oponerse al uso de sus datos personales para fines específicos
          (Oposición). Estos derechos se conocen como derechos ARCO. Grupo Greek
          ha adoptado los niveles de seguridad de protección de datos personales
          legalmente requeridos por la Ley y su Reglamento, bajo los principios
          de licitud, calidad, consentimiento, información, finalidad, lealtad,
          proporcionalidad y responsabilidad. Todo esto bajo estrictas reglas de
          seguridad, confidencialidad y consistencia de información. Grupo Greek
          se reserva el derecho de efectuar en cualquier momento modificaciones
          o actualizaciones al presente aviso de privacidad, para la atención de
          novedades legislativas o jurisprudencias, políticas internas, nuevos
          requerimientos para la prestación u ofrecimientos de nuestros
          servicios y prácticas del mercado, cualquier modificación al Aviso de
          Privacidad estará disponible a través de nuestro portal web, en la
          sección de “aviso de privacidad”. Al navegar en el sitio web de Grupo
          Greek, significa que ha leído, entendido y está de acuerdo con los
          términos antes expuestos.</p>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>
      <Footer />
    </div>
  );
};

export default Aviso;
