import React, { Component, useEffect, useState } from "react";
import {
  collection,
  doc,
  query,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import firebaseConfig from "../Connect/firebase-config";
import { async } from "@firebase/util";
import { Link } from "react-router-dom";

const Historial = () => {
  const db = getFirestore();

  const [history, sethistory] = useState([]);

  return <h1>Hola</h1>;
};

export default Historial;
