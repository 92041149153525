import React from 'react';

const GreekFinance = () => {
  return (
    <div>
      <div className="inner-header flex">
        <h1 className='title-wave'>Desarrollo de software</h1>
        <p>En el mundo de la innovación, nos destacamos como pioneros al
            desarrollar tecnología que está a tu alcance. Enfocados en hacer
            avances tecnológicos accesibles para todos, desde la concepción
            hasta la implementación, nos esforzamos por brindar soluciones
            personalizadas y poderosas. Cada proyecto es una oportunidad para
            demostrar nuestro compromiso con la excelencia, haciendo que la
            tecnología trabaje para ti de una manera que se adapte perfectamente
            a tus necesidades.</p>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>
      <div className="Titulos">Valor que te entregamos</div>
      <section className="container-valor">
        <div className="valor">
          <div className="valor-empresarial">
            <h1>Personalizaci&oacute;n</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                Tu perfil te permite ingresar tu portafolio de inversión y obtener análisis enfocado a tus
                objetivos en combinación con nuestros modelos de aprendizaje automático dedicado a ofrecerte
                estimaciones a corto, mediano y largo plazo, el cual podrás monitorear su precisión mensual 
                y ajustes mensuales desde tu perfil.
              </h2>
            </div>
          </div>
          <div class="valor-empresarial">
            <h1>Transparencia</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                En todos nuestros servicios de inteligencia artificial que se encuentren abiertos a público general
                podrán visualizar su porcentaje de acierto, promedio de error y reajuste mensual, con la finalidad 
                de que nuestros usuarios tengan siempre la decisión de considerar el servicio o notificar cualquier reajuste
                a sus modelos y formen parte del proceso de mejora constante de estos.
              </h2>
            </div>
          </div>
          <div className="valor-empresarial">
            <h1>Dashboard interactivo</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                Tendrás un dashboard moderno, interactivo y fácil de usar que presentará datos complejos de manera simplificada
                para que tanto principiantes como experimentados puedan entender fácilmente la información mejorando la 
                experiencia del usuario.
              </h2>
            </div>
          </div>
          <div class="valor-empresarial">
            <h1>Análisis avanzados</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                Nuestros análisis además de tener modelos de aprendizaje automático, también tendrán 
                indicadores técnicos clásicos que podrás agregar al dashboard, para que puedas mantenerte
                al tanto de los cambios del mercado y complementes tus estrategias.
              </h2>
            </div>
          </div>
          <div class="valor-empresarial">
            <h1>Escalabilidad individual y empresarial</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                Atendemos tanto a inversionistas individuales que
                quieren tener análisis técnicos y de aprendizaje automático
                como a instituciones o empresas con paquetes escalables que 
                se adaptan a diferentes necesidades personalizables, donde,
                nosotros nos encargamos de la infraestructura necesaria para
                hacer funcionar el modelo mientras que nuestros clientes 
                solo tengan que preocuparse por atraer más clientes.
              </h2>
            </div>
          </div>
          <div className="valor-empresarial">
            <h1>Gestión de riesgos</h1>
            {/*<img src={BI} className="IconValor"></img>*/}
            <div className="text-valor">
              <h2>
                Contamos con herramientas de gestión de riesgos
                como análisis de volatibilidad proyectada, índices
                de riesgo personalizados donde simplificando lo
                complejo nuestros clientes puedan tomar decisiones 
                críticas y minimizar las pérdidas de sus portafolios
              </h2>
            </div>
          </div>
        </div>
      </section>

      <div className="Titulos">Ventajas de contratarnos</div>

      <section className="ContainerVentajas">
        <div class="ventajas">
          <div className="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">A medida</h1>
            </div>
            <h2 className="textVentajas">
              Desarrollamos la solución que cubran tus necesidades y se ajusten
              a tu presupuesto. Con nuestros arquitectos y desarrolladores de
              software solucionaremos tus problemas y mejoraremos los procesos
              de tu empresa.
            </h2>
            {/*<img src={Presente} className="VentajasImagen"></img>*/}
          </div>
          <div className="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Tecnologías</h1>
            </div>
            <h2 className="textVentajas">
              Lideramos la implementación de nuevas tecnologías para impulsar la
              innovación en tu empresa. Desde la planificación hasta la
              ejecución, ofrecemos soluciones que marcan el camino hacia la
              excelencia en la era digital.
            </h2>
            {/*<img src={Presente} className="VentajasImagen"></img>*/}
          </div>
          <div class="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Respuestas</h1>
            </div>
            <h2 className="textVentajas">
              Nos destacamos en convertir datos en respuestas significativas.
              Nuestra experiencia en análisis de datos proporciona insights
              valiosos para informar decisiones estratégicas, guiando tu camino
              hacia el éxito informado.
            </h2>
            {/*<img src={Presente} className="VentajasImagen"></img>*/}
          </div>
          <div class="cardVentajas">
            <div className="overlayVentajas"></div>
            <div className="circlecards">
              <h1 className="titleVentajas">Presencia</h1>
            </div>
            <h2 className="textVentajas">
              Nos dedicamos a hacer que tus ideas perduren. Ya sea a través de
              desarrollo, diseño o implementación, aseguramos que tus conceptos
              estén siempre presentes, transformando visiones en realidades.
            </h2>
            {/*<img src={Presente} className="VentajasImagen"></img>*/}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GreekFinance;
