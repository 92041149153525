import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  limit,
  query,
  doc,
  getDoc, // Este es necesario para obtener documentos por ID
} from "firebase/firestore";
import config from "../Connect/firebase-config";

// Inicialización de Firebase
const app = initializeApp(config);
const db = getFirestore(app);

// Función para obtener una lista de módulos con un límite de 5
export const getModulos = async () => {
  try {
    const collectionRef = collection(db, "Modulos"); // Nombres de colección consistentes
    const q = query(collectionRef, limit(5)); // Limitamos la consulta a 5 módulos
    const modulosSnapshot = await getDocs(q); // Obtenemos los documentos
    return modulosSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching modulos:", error);
    throw error;
  }
};

// Función para obtener una lista de certificados con un límite de 5
export const getCertificados = async () => {
  try {
    const collectionRef = collection(db, "Certificaciones"); // Nombres de colección consistentes
    const q = query(collectionRef, limit(5)); // Limitamos la consulta a 5 certificados
    const certificadosSnapshot = await getDocs(q);
    return certificadosSnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching certificados:", error);
    throw error;
  }
};

// Función para obtener un certificado por ID
export const getCertificadoById = async (id) => {
  try {
    const docRef = doc(db, "Modulos", id); // Usamos la referencia a la colección correcta
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, data: docSnap.data() };
    } else {
      throw new Error("No se encontró el certificado con ese ID");
    }
  } catch (error) {
    console.error("Error fetching certificado by ID:", error);
    throw error;
  }
};

// Función para obtener un módulo por ID
export const getModuloById = async (id) => {
  try {
    // Obtenemos el módulo por su ID
    const docRef = doc(db, "Modulos", id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw new Error("No se encontró el módulo con ese ID");
    }

    const moduloData = { id: docSnap.id, data: docSnap.data() };

    // Ahora obtenemos la subcolección 'Clases' dentro del módulo
    const clasesCollectionRef = collection(docRef, "Clases");
    const clasesSnapshot = await getDocs(clasesCollectionRef);
    const clases = clasesSnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));

    // Retornamos tanto el módulo como las clases asociadas
    return { modulo: moduloData, clases };

  } catch (error) {
    console.error("Error al obtener el módulo y sus clases:", error);
    throw error;
  }
};
