import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../estilos/navigation.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Control del estado del menú abierto/cerrado
  const [closing, setClosing] = useState(false); // Control del estado de cierre del menú

  // Función para manejar el clic en el botón de hamburguesa
  const handleHamburgerClick = () => {
    if (menuOpen) {
      setClosing(true); // Activamos la animación de cierre
      setTimeout(() => {
        setMenuOpen(false); // Cerramos el menú completamente después de la animación
        setClosing(false); // Reseteamos el estado de cierre
      }, 500); // Duración de la animación en milisegundos
    } else {
      setMenuOpen(true); // Abrimos el menú
    }
  };

  // Función para cerrar el menú inmediatamente al hacer clic en un enlace
  const closeMenu = () => {
    setMenuOpen(false);
    setClosing(false); // Asegurar que el estado de cierre también se reinicie
  };

  return (
    <>
      <nav>
        <Link to="/" className="title" onClick={closeMenu}>
          Greek
        </Link>

        {/* Menú hamburguesa */}
        <div className="menu" onClick={handleHamburgerClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Menú desplegable */}
        <ul className={`${menuOpen ? "open" : ""} ${closing ? "closing" : ""}`}>
          <li><Link to="/Servicios" onClick={closeMenu}>Servicios</Link></li>
          <li><Link to="/Equipo" onClick={closeMenu}>Equipo</Link></li>
          <li><Link to="/Contacto" onClick={closeMenu}>Contacto</Link></li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
