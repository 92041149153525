import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { getCertificados, getModulos } from "../Control/CertiFire";
import "../estilos/greek.css";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Certificaciones = () => {
  const [modulos, setModulos] = useState([]);
  const [certificados, setCertificados] = useState([]);

  const db = getFirestore();
  const descripcionServicio = "Certificaciones para empresas y escuelas";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modulosData = await getModulos();
        const certificadosData = await getCertificados();
        setCertificados(certificadosData);
        setModulos(modulosData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = (id) => {
    window.location.href = `/Detalles?id=${id}`;
  }

  const handlePay = () => {
    window.location.href = "/Pago";
  }

  return (
    <>
      <Helmet>
        <title>Certificaciones del equipo Greek | Grupo Greek</title>
        <meta name="description" content={descripcionServicio} />
      </Helmet>
      <div className="inner-header flex">
        <h1 className='title-wave'>Desarrollo de software</h1>
        <p>En el mundo de la innovación, nos destacamos como pioneros al
            desarrollar tecnología que está a tu alcance. Enfocados en hacer
            avances tecnológicos accesibles para todos, desde la concepción
            hasta la implementación, nos esforzamos por brindar soluciones
            personalizadas y poderosas. Cada proyecto es una oportunidad para
            demostrar nuestro compromiso con la excelencia, haciendo que la
            tecnología trabaje para ti de una manera que se adapte perfectamente
            a tus necesidades.</p>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>
      <div>
        <h1 className="Titulos">Módulos</h1>
        <div className="main">
          {modulos.map((doc, index) => (
            <React.Fragment key={index}>
              <div className="card">
                <div className="heading">{doc.data.Nombre}</div>
                <div className="details">{doc.data.Descripcion}</div>
                <div className="price">${doc.data.Precio}</div>
                <button className="btn1" onClick={handlePay}>Comprar</button>
                <button className="btn2" onClick={() => handleNavigate(doc.id)}>Detalles</button>
              </div>
              <div className="glasses">
                <img
                  id="image0"
                  width="100"
                  height="100"
                  x="90"
                  y="-10"
                  alt="Data Science"
                />
              </div>
            </React.Fragment>
          ))}
          <div className="containervermas">
            <button className="VerMas">
              Ver más
            </button>
          </div>
        </div>
      </div>
      <div>
        <h1 className="Titulos">Certificaciones</h1>
        <div className="main">
          {certificados.map((doc, index) => (
            <React.Fragment key={index}>
              <div className="card">
                <div className="heading">{doc.data.Nombre}</div>
                <div className="details">{doc.data.Descripcion}</div>
                <div className="price">${doc.data.Precio}</div>
                <button className="btn1" onClick={handlePay}>Comprar</button>
                <button className="btn2" onClick={() => handleNavigate(doc.id)}>Detalles</button>
              </div>
              <div className="glasses">
                <img
                  id="image0"
                  width="100"
                  height="100"
                  x="90"
                  y="-10"
                  src={DataScience}
                  alt="Data Science"
                />
              </div>
            </React.Fragment>
          ))}
          <div className="containervermas">
            <button className="VerMas">
              Ver más
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Certificaciones;
