import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Servicios, setDataContact } from "../Control/Firestore";
import { useLocation } from "react-router-dom";
import '../estilos/contacto.css';

const Contacto = () => {
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [solucion, setSolucion] = useState("");
  const [correo, setCorreo] = useState("");
  const [etapa, setEtapa] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [servicios, setServicios] = useState([]);
  const location = useLocation();
  const [servicioSeleccionado, setServicioSeleccionado] = useState("");
  const [envioExitoso, setEnvioExitoso] = useState(null);

  const contactData = {
    nombre,
    telefono,
    empresa,
    servicioSeleccionado,
    correo,
    etapa,
    descripcion,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const exitoso = await setDataContact(contactData);
    setEnvioExitoso(exitoso);
    setNombre("");
    setTelefono("");
    setEmpresa("");
    setSolucion("");
    setCorreo("");
    setEtapa("");
    setDescripcion("");
  };

  useEffect(() => {
    const fetchDataServices = async () => {
      try {
        const serviciosData = await Servicios();
        console.log("Servicios cargados:", serviciosData);
        setServicios(serviciosData);
      } catch (error) {
        console.error("Error fetching servicios:", error);
      }
    };

    fetchDataServices();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const servicio = searchParams.get("servicio");
    if (servicio) {
      setServicioSeleccionado(servicio);
    }
  }, [location.search]);

  useEffect(() => {
    let timeout;
    if (envioExitoso) {
      timeout = setTimeout(() => {
        setEnvioExitoso(null);
        // Recargar la página después de 5 segundos
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }, 5000); // Mostrar el mensaje durante 5 segundos
    }
    return () => clearTimeout(timeout);
  }, [envioExitoso]);

  return (
    <div>
      <div className="inner-header flex">
        <h1 className='title-wave'>Estamos aquí para ayudarte a aprovechar el poder de los datos</h1>
        <p>¿Listo para transformar tus datos en soluciones inteligentes? Ya sea que tengas una pregunta, quieras saber más sobre nuestros servicios o estés buscando una solución personalizada, estamos disponibles para ayudarte. Completa el formulario a continuación o contáctanos directamente, y uno de nuestros expertos se pondrá en contacto contigo lo antes posible.</p>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>

      <div>
      </div>
      <form class="form_main" action="" onSubmit={handleSubmit}>
        <p class="heading">Contacto</p>
        <div class="inputContainer">
          <input placeholder="Nombre Completo" id="username" class="inputField" type="text"
            maxlength="40"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required />
        </div>

        <input
          placeholder="Número telefónico"
          id="telefono"
          class="inputField"
          type="number"
          value={telefono}
          onChange={(e) => {
            if (e.target.value.length <= 12) {
              setTelefono(e.target.value);
            }
          }}
          required
        />

        <div class="inputContainer">
          <input placeholder="Nombre de la empresa" id="password" class="inputField" type="text"
            maxlength="20"
            value={empresa}
            onChange={(e) => setEmpresa(e.target.value)}
            required />
        </div>

        <div class="inputContainer">
          <select
            className="inputField"
            value={servicioSeleccionado}
            onChange={(e) => setServicioSeleccionado(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Servicio
            </option>
            {Array.isArray(servicios) &&
              servicios.map((servicio) => (
                <option key={servicio.id} value={servicio.id}>
                  {servicio.id}
                </option>
              ))}
          </select>
        </div>

        <div class="inputContainer">
          <input placeholder="Correo empresarial" id="password" class="inputField" type="text"
            maxlength="60"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required />
        </div>

        <div class="inputContainer">
          <select
            class="inputField"
            type="text"
            value={etapa}
            onChange={(e) => setEtapa(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Etapa
            </option>
            <option value="Inicial">Inicial</option>
            <option value="Desarrollo">En desarrollo</option>
            <option value="Avanzada">Avanzada</option>
            <option value="Terminada">Terminada</option>
          </select>
        </div>

        <div class="inputContainer">
          <textarea
            className="inputField"
            placeholder="Describe brevemente tu proyecto"
            pattern="[^a-zA-Z0-9]"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
        </div>


        <button id="button">Enviar</button>
      </form>
      <div>
        {envioExitoso === true && (
          <div className="mensaje mensaje-exito">El envío fue exitoso.</div>
        )}
        {envioExitoso === false && (
          <div className="mensaje mensaje-error">
            Hubo un error en el envío.
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacto;
