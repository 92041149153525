import React, { useState, useEffect } from "react";
import { getModuloById } from "../Control/CertiFire"; // Tus funciones de consulta a Firebase
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import "../estilos/detalles.css";
import "../estilos/greek.css";

// Hook para obtener el query parameter de la URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Detalles = () => {
  const query = useQuery();
  const id = query.get("id"); // Obtener el id del query parameter de la URL
  const [modulo, setModulo] = useState(null); // Para los módulos
  const [clases, setClases] = useState([]); // Para las clases
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null); // Estado para el acordeón

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          // Llamar a la función para obtener los módulos y clases
          const { modulo, clases } = await getModuloById(id); // Asegúrate de que la función regresa el campo "Nombre" y "Aprendizajes"
          setModulo(modulo);
          setClases(clases); // Aquí guardamos las clases
        } else {
          throw new Error("No se proporcionó un ID en la URL");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setError(error.message);
      }
    };

    fetchData();
  }, [id]);

  const toggleAccordion = (index) => {
    console.log("Current index:", index);
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (!modulo) {
    // Si el módulo no ha sido cargado aún, mostramos un mensaje de carga o un valor por defecto
    return <p>Cargando datos del módulo...</p>;
  }

  return (
    <div>
      <Helmet>
        <title>Certificaciones del equipo Greek | Grupo Greek</title>
        <meta name="description" content="Detalles del certificado o módulo seleccionado" />
      </Helmet>

      <div className="inner-header flex">
        <h1 className='title-wave'>Desarrollo de software</h1>
        <p>En el mundo de la innovación, nos destacamos como pioneros al
            desarrollar tecnología que está a tu alcance. Enfocados en hacer
            avances tecnológicos accesibles para todos, desde la concepción
            hasta la implementación, nos esforzamos por brindar soluciones
            personalizadas y poderosas. Cada proyecto es una oportunidad para
            demostrar nuestro compromiso con la excelencia, haciendo que la
            tecnología trabaje para ti de una manera que se adapte perfectamente
            a tus necesidades.</p>
      </div>

      <div>
        <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use href="#gentle-wave" x="48" y="0" fill="rgba(15, 120, 126, 0.7" />
            <use href="#gentle-wave" x="48" y="3" fill="rgba(15, 120, 126, 0.5)" />
            <use href="#gentle-wave" x="48" y="5" fill="rgba(15, 120, 126, 0.3)" />
            <use href="#gentle-wave" x="48" y="7" fill="#0f787e" />
          </g>
        </svg>
      </div>

      <div className="main-container">
        <div className="main-aprendizaje">
          <div className="card-aprendizaje">
            <div className="heading-aprendizaje">¿Qué aprenderás?</div>
            {/* Verificamos que modulo no sea null antes de acceder a modulo.data */}
            {modulo?.data ? (
              <div className="details">
                <h3>{modulo.data.Nombre}</h3> {/* Mostrar el nombre del módulo */}
                <ul>
                  {/* Iterar sobre el arreglo de Aprendizajes */}
                  {modulo.data.Aprendizajes && modulo.data.Aprendizajes.length > 0 ? (
                    modulo.data.Aprendizajes.map((aprendizaje, index) => (
                      <li key={index}>{aprendizaje}</li>
                    ))
                  ) : (
                    <li>No hay aprendizajes disponibles.</li>
                  )}
                </ul>
              </div>
            ) : (
              <p>Cargando datos del módulo...</p>
            )}
          </div>
        </div>

        {/* Acordeón para mostrar las clases */}
        <div className="accordion">
          {clases.map((clase, index) => (
            <div key={clase.id} className="accordion-item">
              <div
                className={`accordion-title ${activeIndex === index ? "active" : ""}`}
                onClick={() => toggleAccordion(index)} // Pasamos el índice correcto
              >
                {/* Mostrar el id del documento como título */}
                <h3 className="accordion-titulo">{clase.id}</h3>
                <span className="accordion-clases">Duración: {clase.data.Duracion} horas</span>
              </div>
              <div className={`accordion-content ${activeIndex === index ? "show" : ""}`}>
                <p className="accordion-description">{clase.data.Descripcion}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="card-compra">
          <div className="header-compra">
            <span className="title-compra">Comprar ahora</span>
            <span className="price-compra">${modulo?.data?.Precio || "Cargando..."}</span>
          </div>
          <p className="desc">
            Esto te dará acceso ilimitado al curso que estás seleccionando, además de tu examen de certificación.
          </p>
          <ul class="lists">
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Pago en línea</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Transferencia</span>
          </li>
          <li class="list">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span>Pago seguro</span>
          </li>
        </ul>
          <button type="button" className="action">Pagar</button>
        </div>

        <div className="card-master">
          <div className="image"></div>
          <div className="card-info">
            <span>Mauricio Jiménez</span>
            <p>Científico de datos</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Detalles;
